import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { StripeSubStatus } from "@utils/types";

// Define a type for the slice state
interface PluginState {
  authToken: string | undefined;
  figmaUrl: string | undefined;
  filename: string | undefined;
  documentId: string | undefined;
  buildSessionID: string | undefined;
  codeSessionID: string | undefined;
  showFeedback: boolean;
  user: User | undefined;
  nodeId: string | undefined;
  premiumFeatureTriggerText: string | undefined;
}

export interface User {
  access_code: string;
  email: string;
  handle: string;
  has_access: boolean;
  id: string;
  img_url: string;
  user_config: UserConfig;
  days_remaining: number;
  completed_first_codegen: boolean;
  onboarding_completed: boolean;
  payment_status: StripeSubStatus;
}

interface UserConfig {
  baseTextSize: number;
  paddingUnit: string;
  marginUnit: string;
  outputLanguage: string;
  StyleSheetConfig: string;
  library_component_id: number;
}

// Define the initial state using that type
const initialState: PluginState = {
  authToken: "",
  filename: undefined,
  figmaUrl: undefined,
  documentId: undefined,
  buildSessionID: undefined,
  codeSessionID: undefined,
  user: undefined,
  nodeId: undefined,
  showFeedback: false,
  premiumFeatureTriggerText: undefined
};

export const pluginSlice = createSlice({
  name: "plugin",
  initialState,
  reducers: {
    setAuthToken: (state, actions) => {
      state.authToken = actions.payload;
    },
    setUser: (state, actions) => {
      state.user = actions.payload;
    },
    setFigmaUrl: (state, actions) => {
      state.figmaUrl = actions.payload;
    },
    setDocumentId: (state, actions) => {
      state.documentId = actions.payload;
    },
    setBuildSessionID: (state, actions) => {
      state.buildSessionID = actions.payload;
    },
    setNodeId: (state, actions) => {
      state.nodeId = actions.payload;
    },
    setCodeSessionId: (state, actions) => {
      state.codeSessionID = actions.payload;
    },
    setState: (state, actions) => {
      const { key, value } = actions.payload;
      state[key] = value;
    },
    setShowFeedback: (state, actions) => {
      state.showFeedback = actions.payload;
    },
    setPremiumFeatureTriggerText: (state, actions) => {
      state.premiumFeatureTriggerText = actions.payload;
    },
    resetState: (state, actions) => {
      state = initialState;
    },
  },
});

export const {
  setAuthToken,
  setUser,
  setFigmaUrl,
  setDocumentId,
  setState,
  resetState,
  setBuildSessionID,
  setNodeId,
  setCodeSessionId,
  setShowFeedback,
  setPremiumFeatureTriggerText
} = pluginSlice.actions;
export const selectAuthToken = (state: RootState) => state.plugin.authToken;
export const selectUser = (state: RootState) => state.plugin.user;
export const selectFigmaUrl = (state: RootState) => state.plugin.figmaUrl;
export const selectDocumentId = (state: RootState) => state.plugin.documentId;
export const selectBuildSessionID = (state: RootState) =>
  state.plugin.buildSessionID;
export const selectNodeId = (state: RootState) => state.plugin.nodeId;
export const selectShowFeedback = (state: RootState) =>
  state.plugin.showFeedback;
export const selectCodeSessionID = (state: RootState) =>
  state.plugin.codeSessionID;
export default pluginSlice.reducer;
export const selectFileName = (state: RootState) => state.plugin.filename;
export const selectPremiumFeatureTriggerText = (state: RootState) => state.plugin.premiumFeatureTriggerText;
