import axios, { AxiosPromise } from "axios";
import {
  Dependancy,
  ExternalFile,
  Folder,
} from "src/views/CodeResults/helpers";
import {
  authParams,
  backendUrl,
  buildURL,
  codegenUrl,
  sendInBlueLink,
  SENDINBLUE_API_KEY,
} from "./consts";
import {
  StateData,
  StripeSessionInfo,
  StripeSubStatus,
  UserInfo,
} from "./types";

export function postFigmaUrl(
  authToken: string,
  figmaUrl: string,
  fileName: string
): any {
  return axios({
    method: "POST",
    url: `${backendUrl}/figmaurl`,
    headers: { authentication: authToken },
    data: {
      figmaUrl: figmaUrl,
      name: fileName,
    },
  });
}

export function auth(state: StateData, code: string): any {
  return axios({
    method: "GET",
    url: `${backendUrl}/auth`,
    headers: {},
    data: {},
    params: {
      state: state,
      code: code,
      redirect_url: authParams.redirect_uri,
    },
  });
}

export function generateStripeSessionLink(
  data: StripeSessionInfo,
  authToken: string
): AxiosPromise<{ redirectURL: string }> {
  return axios({
    method: "POST",
    url: `${backendUrl}/signupstripe`,
    data: data,
    headers: {
      authentication: authToken,
    },
  });
}

export function getUserInfo(authToken: string): AxiosPromise<UserInfo> {
  return axios({
    method: "GET",
    url: `${backendUrl}/user_info`,
    headers: {
      authentication: authToken,
    },
  });
}

export function getSubscriptionStatus(
  authToken: string
): AxiosPromise<StripeSubStatus> {
  return axios({
    method: "GET",
    url: `${backendUrl}/subscription/status`,
    headers: {
      authentication: authToken,
    },
  });
}

export function completedOnboarding(authToken: string): any {
  return axios({
    method: "PATCH",
    url: `${backendUrl}/user/completed_onboarding`,
    headers: {
      authentication: authToken,
    },
  });
}

export function completedFirstCodegen(authToken: string): any {
  return axios({
    method: "PATCH",
    url: `${backendUrl}/user/completed_first_codegen`,
    headers: {
      authentication: authToken,
    },
  });
}

export function verifyAccessToken(accessCode: string, authToken: string): any {
  return axios({
    method: "POST",
    url: `${backendUrl}/access_code`,
    headers: {
      authentication: authToken,
    },
    data: { accessCode: accessCode },
  });
}

export function authListener(state: string): any {
  return axios({
    method: "GET",
    url: `${backendUrl}/listen/${state}`,
    headers: {},
    data: {},
    params: {},
  });
}

export const getStylingFiles = (
  authToken: string,
  file_id: string,
  contents: string
): any => {
  return axios({
    method: "GET",
    url: `${backendUrl}/file/${file_id}/styling_files`,
    headers: {
      authentication: authToken,
    },
    params: {},
    data: {
      contents,
    },
  });
};

export const postStylingFiles = (
  authToken: string,
  file_id: string,
  file_name: string,
  contents: string
): any => {
  return axios({
    method: "PUT",
    url: `${backendUrl}/file/${file_id}/styling_files?file_name=${file_name}`,
    headers: {
      authentication: authToken,
    },
    params: {},
    data: {
      contents,
      file_name,
    },
  });
};

export const deleteStylingFiles = (authToken: string, file_id: string) => {
  return axios({
    method: "DELETE",
    url: `${backendUrl}/file/${file_id}/styling_files`,
    headers: {
      authentication: authToken,
    },
    params: {},
    data: {},
  });
};

export const checkStylingFiles = (authToken: string, file_id: string) => {
  return axios({
    method: "GET",
    url: `${backendUrl}/file/${file_id}/styling_files`,
    headers: {
      authentication: authToken,
    },
    params: {},
    data: {},
  });
};

export const buildInitialize = (
  dependencies?: Dependancy[],
  externalFiles?: ExternalFile[],
  codeType?: string,
  baseTextSize?: number
) => {
  return axios({
    method: "POST",
    url: `${buildURL}/initialize`,
    data: {
      dependencies: dependencies ? dependencies : [],
      external_files: externalFiles ? externalFiles : [],
      extension: codeType,
      base_text_size: baseTextSize,
    },
  });
};

export const syncFS = (sessionID: string, folder: Folder) => {
  return axios({
    method: "POST",
    url: `${buildURL}/sync/${sessionID}`,
    data: folder,
  });
};

export const renderFile = (
  sessionID: string,
  path: string,
  name: string,
  extension: string,
  additionalImports?: string
) => {
  return axios({
    method: "POST",
    url: `${buildURL}/render/${sessionID}`,
    data: {
      ComponentPathRelative: path,
      ComponentName: name,
      AdditionalImports: additionalImports,
      Extension: extension,
    },
  });
};

export const getCode = (
  authToken: string,
  documentID: string,
  image: Uint8Array,
  nodeID: string
) => {
  const formData = new FormData();
  const blob = new Blob([image], { type: "image/png" });
  formData.append("node_image", blob);
  // formData.append("selection_id", nodeID);
  return axios({
    method: "POST",
    url: `${codegenUrl}/generate/${documentID}/${nodeID}`,
    headers: {
      "x-figma-api-key": authToken,
    },
    params: {},
    data: formData,
  });
};
const FIGMA_SIGNUP_LIST_ID = 5;

export function sendinblueApi(email: string, data: any): any {
  return axios({
    method: "POST",
    url: sendInBlueLink,
    headers: { "api-key": SENDINBLUE_API_KEY },
    data: {
      email: email,
      updateEnabled: true,
      attributes: data,
      listIds: [FIGMA_SIGNUP_LIST_ID],
    },
  });
}

export function saveUserConfig(authToken: string, data: any): any {
  return axios({
    method: "PUT",
    url: `${backendUrl}/user_config`,
    headers: {
      authentication: authToken,
    },
    data: data,
  });
}

export const getCachedResults = (authToken: string, sessionID: string) => {
  return axios({
    method: "GET",
    url: `${codegenUrl}/session/cachedresults/${sessionID}`,
    headers: {
      "x-figma-api-key": authToken,
    },
    params: {},
  });
};

export const regenerateCodegen = (authToken: string, sessionID: string) => {
  const formData = new FormData();
  formData.append("refresh", "true");
  return axios({
    method: "POST",
    url: `${codegenUrl}/session/regenerate/${sessionID}`,
    headers: {
      "x-figma-api-key": authToken,
    },
    params: {},
    data: formData,
  });
};
