import React, { useState } from "react";
import visa from "@assets/survey/step4/visa.svg";
import gem from "@assets/gem.svg";
import masterCard from "@assets/survey/step4/masterCard.svg";
import "./PlanCard.css";
import { PlanDetails } from "..";
import { getPaymentPageRedirect, openInNewTab } from "@utils/redirects";
import { useSelector } from "react-redux";
import { selectUser } from "@store/plugin";
import { paywallCtaClick, trackEvent } from "@utils/analytics";

interface Props {
  plan: PlanDetails;
  viewMode: "browser" | "plugin";
}

const PlanCard: React.FC<Props> = ({ plan, viewMode }) => {
  const [isGeneratingStripeUrl, setIsGeneratingStripeUrl] = useState(false);
  const user = useSelector(selectUser);

  const onSubscribeClick = () => {
    setIsGeneratingStripeUrl(true);
    trackEvent(
      paywallCtaClick,
      {
        "stripe-product": plan.stripePlanId,
        "stripe-product-price": plan.price,
      },
      viewMode === "browser" ? "login" : viewMode
    );
    getPaymentPageRedirect(user, plan, viewMode)
      .then((url: string) => {
        openInNewTab(url);
        setIsGeneratingStripeUrl(false);
      })
      .catch((_) => {
        setIsGeneratingStripeUrl(false);
      });
  };

  return (
    <div className="planCard">
      <div className="flex flex-col">
        <div className="planHeader">
          <div className="flex items-center mb-2">
            <img src={gem} alt="" />
            <h4 className="ml-1">{plan.title}</h4>
          </div>
          <p dangerouslySetInnerHTML={{ __html: plan.subTitle }} />
        </div>
        <div className="planBody">
          <div className="">
            <div className="price">
              <div className={`flex items-center line-through`}>
                <h4>${plan.originalPrice}</h4>
                <p>/{plan.time}</p>
              </div>
              {/* <span className="discount">{`$${plan.discount} ${plan.offerName}`}</span> */}
            </div>
            <div className="finalPriceContainer">
              <div className="finalPrice">
                <h4>{`Now $${plan.price}`}</h4>
                <p>/{plan.time}</p>
              </div>
              <span className="discount">{plan.validityText}</span>
            </div>
          </div>
          <div className="paymentType">
            <img src={visa} alt="" />
            <img src={masterCard} alt="" />
          </div>
        </div>

        <button
          className="planFooter"
          disabled={isGeneratingStripeUrl}
          onClick={onSubscribeClick}
        >
          <h5>Start Free Trial</h5>
          <span>Cancel anytime</span>
        </button>
      </div>
    </div>
  );
};

export default PlanCard;
