import { CodeLoadingScreen } from "@components/CodeLoadingScreen";
import { store } from "@store/index";
import {
  setAuthToken,
  setCodeSessionId,
  setDocumentId,
  setNodeId,
  setShowFeedback,
} from "@store/plugin";
import { identifyUser } from "@utils/analytics";
import {
  completedFirstCodegen,
  getCachedResults,
  getUserInfo,
} from "@utils/api";
import { storeUser } from "@utils/auth";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CodegenBrowser = () => {
  const params = useParams();
  const sessionId = params.sessionId || "";
  const authToken = params.authToken || "";
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(setAuthToken(authToken));
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("sessionId", sessionId);
    store.dispatch(setCodeSessionId(sessionId));
    store.dispatch(setShowFeedback(false));
    completedFirstCodegen(authToken || "");
    getUserInfo(authToken || "").then((userInfo) => {
      const user = userInfo.data;
      if (user && user.id) {
        storeUser(user);
        identifyUser(user);
      }
    });
    getCachedResults(authToken, sessionId).then((response) => {
      store.dispatch(setDocumentId(response.data.document_id));
      store.dispatch(setNodeId(response.data.node_id));
      store.dispatch(setShowFeedback(true));
      navigate("/coderesults", { state: { code: response.data } });
    });
  }, []);

  return <CodeLoadingScreen />;
};
export default CodegenBrowser;
