import { FeedbackWrapper } from "@components/Feedback";
import LoadingScreen from "@components/LoadingScreen";
import { Navbar } from "@components/Navbar";
import { SettingsWidget } from "@components/SettingsWidget/Widget";
import { store } from "@store/index";
import {
  selectBuildSessionID,
  selectPremiumFeatureTriggerText,
  selectUser,
  setBuildSessionID,
  setPremiumFeatureTriggerText,
  setUser,
} from "@store/plugin";
import { buildInitialize, getSubscriptionStatus, getUserInfo, syncFS } from "@utils/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CodeResult,
  Folder,
  FolderItem,
  newImageFolderItem,
  resultToFolders,
} from "./helpers";
import { LeftPanel } from "./LeftPanel";
import { RightPanel } from "./RightPanel";
import closeBlue from "@assets/closeBlue.svg";
import logoSurvey from "@assets/survey/logoSurvey.svg";
import gem from "@assets/gem.svg";
import { openInNewTab } from "@utils/redirects";
import { identifyUser } from "@utils/analytics";
import { storeUser } from "@utils/auth";

const fetchAssets = (code: CodeResult): FolderItem[] => {
  // get all the images in our code
  const images = code.components.flatMap((c) => c.images.map((im) => im));
  images.push(...code.entry.images);

  // Convert into dictionary of  ImageID : FolderItems
  const imageDict: Map<string, FolderItem> = new Map();

  images.forEach((image) => imageDict.set(image.id, newImageFolderItem(image)));

  const assetContents: FolderItem[] = Array.from(imageDict.values());

  return assetContents;
};

const CodeResults = () => {
  const location = useLocation();
  const code = location.state["code"] as CodeResult;
  const [openSettings, setOpenSettings] = useState(false);
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const user = useSelector(selectUser);
  const premiumFeatureTriggerText = useSelector(
    selectPremiumFeatureTriggerText
  );
  const [codeType, setCodeType] = useState(
    user?.user_config?.outputLanguage || "tsx"
  );
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [selected, setSelectedItem] = useState(
    new Folder("", []) as FolderItem | Folder
  );
  const sessionID = useSelector(selectBuildSessionID);
  const setFile = (_: FolderItem) => {};
  const [codeFolder, setCodeFolder] = useState<Folder | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
      if (authToken) {
        getSubscriptionStatus(authToken!)
          .then((resp) => {
            if (resp.data.active) {
              getUserInfo(authToken).then((resp: any) => {
                const userInfo = {
                  id: resp.data.id,
                  handle: resp.data.handle,
                  img_url: resp.data.img_url,
                  email: resp.data.email,
                };

                identifyUser(userInfo);
                setUser(resp.data);
                storeUser(resp.data);
              });
            }
          })
          .catch((error: any) => {
            console.log("error verifying subscription status:", error);
          });
      }
  }, []);
  
  useEffect(() => {
    if (codeFolder) {
      return;
    }
    const folder = resultToFolders(code);
    setCodeFolder(folder);
    if (folder.getItem(`/src/App.jsx`))
      setSelectedItem(folder.getItem(`/src/App.jsx`) as FolderItem);
    else if (folder.getItem(`/src/App.tsx`))
      setSelectedItem(folder.getItem(`/src/App.tsx`) as FolderItem);
    setAssetsLoading(true);
  }, [user]);

  // Get SessionID
  useEffect(() => {
    // if (sessionID) {
    //   return;
    // }
    buildInitialize(
      code.dependencies,
      code.externalFiles,
      codeType,
      user?.user_config?.baseTextSize
    ).then(
      (x) => {
        const sessionID = x.data["SessionID"];
        console.info("Got SessionID", sessionID);
        store.dispatch(setBuildSessionID(sessionID));
      },
      (err) => {
        console.error("err", err);
      }
    );
  }, []);

  // Sync Files on Change`of code or sessionID
  useEffect(() => {
    if (!sessionID || !codeFolder || codeFolder.contents.length === 0) {
      return;
    }
    syncFS(sessionID, codeFolder);
  }, [codeFolder, sessionID]);

  useEffect(() => {
    if (!assetsLoading || !codeFolder) {
      return;
    }
    const contents = fetchAssets(code);
    const newFolder = { ...codeFolder } as Folder;
    const assets = new Folder("assets", contents);
    newFolder.replaceContents([assets, ...codeFolder.contents]);
    setCodeFolder(newFolder);
    setAssetsLoading(false);
  }, [assetsLoading]);

  if (!codeFolder) return <></>;
  if (updatingSettings) return <LoadingScreen message="Updating Settings" />;
  return (
    <div className="h-screen absolute relative">
      {premiumFeatureTriggerText && (
        <div className="absolute flex w-full h-full justify-center items-center z-[99] bg-[#000000]/[0.8]">
          <div className="bg-[#F5F5F5] flex flex-col px-4 py-6 rounded gap-5 w-[430px] items-center text-center">
            <button
              className="self-end"
              onClick={() => {
                store.dispatch(setPremiumFeatureTriggerText(undefined));
              }}
            >
              <img src={closeBlue} alt="<" className="w-[18px]" />
            </button>
            <div className="flex flex-col px-4 pb-4 rounded gap-5 items-center text-center">
              <img src={logoSurvey} alt="Logo" className="w-[165px]" />
              <span className="font-bold">
                {premiumFeatureTriggerText} is premium feature
              </span>
              <span>
                To enjoy using copycat premium features please become a premium
                member
              </span>
              <button
                type="button"
                className={`flex items-center justify-center gap-[2px] w-[45%] cursor-pointer shadow-button-shadow bg-[#6930CA] rounded-xl text-center text-[12px] font-[500] text-white py-[13px] px-[12px]`}
                onClick={() => navigate("/survey?paymentStatus=init")}
              >
                <img src={gem} alt="" className="w-[18px]" />
                Become Premium
              </button>
            </div>
          </div>
        </div>
      )}

      <Navbar />
      <div className="flex flex-row h-full">
        <FeedbackWrapper minimize={minimize} />
        <LeftPanel
          codeFolder={codeFolder}
          selected={selected}
          setSelectedItem={setSelectedItem}
          showLoader={assetsLoading}
          minimize={minimize}
          setMinimize={setMinimize}
          sessionID={sessionID!}
          setOpenSettings={setOpenSettings}
          openSettings={openSettings}
          user={user}
        />
        {!minimize && (
          <RightPanel
            selectedFile={selected}
            updateSelectedFile={setFile}
            assetsLoading={assetsLoading}
            setOpenSettings={setOpenSettings}
            openSettings={openSettings}
            setUpdatingSettings={setUpdatingSettings}
          />
        )}
      </div>
    </div>
  );
};

export default CodeResults;
