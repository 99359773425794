import React, { useEffect, useMemo, useState } from "react";
import {
  identifyUser,
  onboardingContinueClick,
  onboardingSubmitClick,
  onboardingFamiliarityClick,
  onboardingPurposeClick,
  onboardingSpecialityClick,
  trackEvent,
  paywallSeen,
  paymentSuccess,
} from "@utils/analytics";
import {
  completedOnboarding,
  getSubscriptionStatus,
  getUserInfo,
} from "@utils/api";
import BigCatLogo from "@assets/cat-withback.svg";
import Check from "@assets/circle-check-fill.png";
import chevronLeftBlack from "@assets/survey/chevronLeftBlack.svg";
import chevronRightBlack from "@assets/survey/chevronRightBlack.svg";
import logoSurvey from "@assets/survey/logoSurvey.svg";
import SurveyStepFour from "./SurveyStepFour";
import SurveyStepOne from "./SurveyStepOne";
import SurveyStepThree from "./SurveyStepThree";
import SurveyStepTwo from "./SurveyStepTwo";
import Alert from "./Alert";
import "./Survey.css";
import { useSelector } from "react-redux";
import { selectAuthToken, selectUser } from "@store/plugin";
import { useSearchParams } from "react-router-dom";
import PaymentSuccess from "@components/PyamentSuccess";
import { ENABLE_MANDATE_PAYMENTS } from "@utils/consts";
interface formDataType {
  jobUser: string;
  specialty: string;
  familiarity: string;
  intention: string;
  subscription: string;
}

export type stepsPropsType = {
  formData: formDataType;
  setFormData: React.Dispatch<React.SetStateAction<formDataType>>;
  setActiveAlert: React.Dispatch<React.SetStateAction<boolean>>;
  checked?: boolean;
  setOthersSelected?: React.Dispatch<
    React.SetStateAction<{ thirdPage: boolean }>
  >;
};

type PaymentStatusType = "init" | "success" | "cancel" | null;
type PaymentTriggerSource = "browser" | "plugin";

const Survey = () => {
  const user = useSelector(selectUser) || "";
  const authToken = useSelector(selectAuthToken) || "";
  const [searchParams] = useSearchParams();
  const paymentStatusQueryparam = searchParams.get(
    "paymentStatus"
  ) as PaymentStatusType;
  const paymentTriggerSource = searchParams.get(
    "triggerSource"
  ) as PaymentTriggerSource;
  const planId = searchParams.get("planId") as string;
  const planPrice = searchParams.get("planPrice") as string;
  const showPaymentStep = ENABLE_MANDATE_PAYMENTS || paymentStatusQueryparam;
  const defaultPageStep = paymentStatusQueryparam ? 4 : 1;
  const [page, setPage] = useState<number>(defaultPageStep);
  const [activeAlert, setActiveAlert] = useState(false);
  const [successfulLogin, setSuccessfulLogin] = useState<boolean>(false);
  const [successfulPayment, setSuccessfulPayment] = useState<boolean>(false);
  const [width, setWidth] = useState(100);
  const [othersSelected, setOthersSelected] = useState({ thirdPage: false });
  const [formData, setFormData] = useState<formDataType>({
    jobUser: "",
    specialty: "",
    familiarity: "",
    intention: "",
    subscription: showPaymentStep ? "on" : "off",
  });
  useEffect(() => {
    if (showPaymentStep) setWidth(Math.max(page * 25, 100));
    else setWidth(Math.max(page * 33.33, 100));
    if (showPaymentStep && page === 4) {
      trackEvent(paywallSeen, {}, "login");
    }
  }, [page, showPaymentStep]);

  useEffect(() => {
    if (paymentStatusQueryparam === "success") {
      const token = localStorage.getItem("authToken");
      getSubscriptionStatus(token!).then((resp) => {
        if (resp.data.active) {
          getUserInfo(token!).then((userInfo) => {
            const user = userInfo.data;
            if (user && user.id) {
              identifyUser(user);
              trackEvent(
                paymentSuccess,
                {
                  "stripe-product": planId,
                  "stripe-product-price": planPrice,
                },
                paymentTriggerSource === "browser"
                  ? "login"
                  : paymentTriggerSource
              );
            }
          });
          setSuccessfulPayment(true);
        } else {
          //setting step to payment
          setPage(4);
        }
      });
    }
  }, [paymentStatusQueryparam]);

  useEffect(() => {
    if (user) {
      const checkForPayment = () => {
        if (showPaymentStep) {
          return user.payment_status && user.payment_status.active;
        }
        return true;
      };
      const userInfo = {
        id: user.id,
        handle: user.handle,
        img_url: user.img_url,
        email: user.email,
      };
      identifyUser(userInfo);
      if (user.onboarding_completed && checkForPayment()) {
        setSuccessfulLogin(true);
      }
    }
  }, [user]);

  let lastStep = showPaymentStep ? 4 : 3;

  const IsDataEmpty =
    ((!formData.jobUser || !formData.specialty) && page === 1) ||
    (!formData.familiarity && page === 2) ||
    (!formData.intention && page === 3) ||
    (!formData.subscription && page === 4);

  const TrackCurrentQuestion = useMemo(() => {
    let value = "";
    switch (page) {
      case 1:
        value = "What’s your specialty?";
        break;
      case 2:
        value = "How familiar are you with React.js?";
        break;
      case 3:
        value = "primary intention";
        break;
      case 4:
        value = "your subscription";
        break;
    }
    return value;
  }, [page]);

  const TrackCurrentSelectedOption = useMemo(() => {
    return page === 1
      ? `${formData.jobUser} - ${formData.specialty}`
      : page === 2
      ? formData.familiarity
      : page === 3 && othersSelected.thirdPage
      ? `Others -  ${formData.intention}`
      : page === 3 && !othersSelected.thirdPage
      ? formData.intention
      : page === 4
      ? formData.subscription
      : "";
  }, [page, formData]);

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    skip?: boolean
  ) => {
    e.preventDefault();
    // if (page !== lastStep - 1) return;
    trackEvent(
      onboardingSubmitClick,
      {
        question: TrackCurrentQuestion,
        selection: skip ? "Skipped" : TrackCurrentSelectedOption,
      },
      "browser"
    );
    completedOnboarding(authToken).then(() => {
      if (showPaymentStep) {
        setPage((currPage) => currPage + 1);
      } else {
        setSuccessfulLogin(true);
      }
    });

    // formData is the data collected from the steps and should be sent to the server, and if it was successful, then we set SuccessfulLogin state to true
  };

  const getEventTrackingPayload = (page: number) => {
    const pageToTrackingTagMap: Record<number, string> = {
      1: onboardingSpecialityClick,
      2: onboardingFamiliarityClick,
      3: onboardingPurposeClick,
    };
    const finalPayload = {
      tag: pageToTrackingTagMap[page],
      payload: {},
    };
    switch (page) {
      case 1:
        finalPayload["payload"] = { speciality: TrackCurrentSelectedOption };
        break;
      case 2:
        finalPayload["payload"] = { familiarity: TrackCurrentSelectedOption };
        break;
      case 3:
        finalPayload["payload"] = { purpose: TrackCurrentSelectedOption };
        break;
      default:
        break;
    }
    return finalPayload;
  };
  const getPrimaryCTA = (page: number) => {
    if (
      (showPaymentStep && page < lastStep - 1) ||
      (!showPaymentStep && page < lastStep)
    ) {
      return (
        <button
          className="btn btnGold btnContinue"
          key="continueBtn"
          onClick={() => {
            if (IsDataEmpty) {
              setActiveAlert(true);
            } else {
              const trackingPayload = getEventTrackingPayload(page);
              trackEvent(
                trackingPayload.tag,
                trackingPayload.payload,
                "browser"
              );
              setPage((currPage) => currPage + 1);
              setActiveAlert(false);
            }
          }}
          type="button"
        >
          Continue
          <img src={chevronRightBlack} alt="chevron" />
        </button>
      );
    } else if (
      (showPaymentStep && page === lastStep - 1) ||
      (!showPaymentStep && page === lastStep)
    ) {
      return (
        <button
          className="btn btnGold btnContinue"
          key="submitBtn"
          onClick={(event) => {
            if (!formData.intention) {
              setActiveAlert(true);
            } else if (formData.intention) {
              setActiveAlert(false);
            } else {
              handleSubmit(event);
            }
          }}
          type="submit"
        >
          Submit
          <img src={chevronRightBlack} alt="chevron" />
        </button>
      );
    } else {
      return <></>;
    }
  };

  const getSecondaryCTA = (page: number) => {
    if (page === 1 || (showPaymentStep && page === 4)) {
      return <></>;
    } else {
      return (
        <button
          className="btn btnWhite mr-4"
          onClick={() => {
            setPage((currPage) => currPage - 1);
            setActiveAlert(false);
          }}
          type="button"
        >
          <div>
            <img src={chevronLeftBlack} alt="Chevron" />
            <span>Back</span>
          </div>
        </button>
      );
    }
  };

  if (successfulLogin) {
    return (
      <div className="mx-auto flex flex-col items-center w-screen h-screen bg-[#FFD803] justify-center">
        <div className="flex-col justify-center items-center">
          <img src={BigCatLogo} className="mx-auto h-1/2" />
          <div className="flex justify-center">
            <h1 className=" mt-[37px] inline-flex text-[53px] items-center text-center text-black font-[700] ">
              <img src={Check} className="mr-[18px] h-[61px] " />
              Login Successful
            </h1>
          </div>
          <div className="mx-auto mt-[50px] text-[30px] font-regular text-center text-black">
            You can now get back to Figma and enjoy CopyCat!
          </div>
          <br />
        </div>
      </div>
    );
  }

  // This is the fix for flicker. `successFulPayment` is a state that is taking time to update
  // in the effects above hence causing the flicker issue.
  if (paymentStatusQueryparam === "success" && !successfulPayment) return <></>;

  if (successfulPayment) return <PaymentSuccess />;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e as any);
      }}
    >
      <section className="surveySteps">
        <div className="step">
          <div className="stepHeader">
            <div className="stepHeader__right">
              <img src={logoSurvey} alt="Logo" />
              <p>Help us improve and customize your experience</p>
            </div>
            <div className="stepHeader__left">
              <h4>
                Step {page}/{lastStep}
              </h4>
            </div>

            <div className="progressbar">
              <div
                style={{
                  width: width + "%",
                }}
              ></div>
            </div>
          </div>

          <PageDisplay
            page={page}
            formData={formData}
            setFormData={setFormData}
            setActiveAlert={setActiveAlert}
            setOthersSelected={setOthersSelected}
            showPaymentStep={paymentStatusQueryparam !== undefined || paymentStatusQueryparam !== null}
          />

          {activeAlert && <Alert />}

          <div className="stepFooter">
            <p>
              Made with <span>❤</span> using CopyCat
            </p>
            <div className="btnGroups">
              {getSecondaryCTA(page)}
              {getPrimaryCTA(page)}
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default Survey;

type pageDisplayPropsType = {
  page: number;
  formData: formDataType;
  showPaymentStep: boolean;
  setFormData: React.Dispatch<React.SetStateAction<formDataType>>;
  setActiveAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setOthersSelected: React.Dispatch<
    React.SetStateAction<{ thirdPage: boolean }>
  >;
};

const PageDisplay = ({
  page,
  formData,
  showPaymentStep,
  setFormData,
  setActiveAlert,
  setOthersSelected,
}: pageDisplayPropsType) => {
  if (page === 1) {
    return (
      <SurveyStepOne
        formData={formData}
        setFormData={setFormData}
        setActiveAlert={setActiveAlert}
      />
    );
  } else if (page === 2) {
    return (
      <SurveyStepTwo
        formData={formData}
        setFormData={setFormData}
        setActiveAlert={setActiveAlert}
      />
    );
  } else if (page === 3) {
    return (
      <SurveyStepThree
        formData={formData}
        setFormData={setFormData}
        setActiveAlert={setActiveAlert}
        setOthersSelected={setOthersSelected}
      />
    );
  } else if (page === 4 && (ENABLE_MANDATE_PAYMENTS || showPaymentStep)) {
    return (
      <SurveyStepFour
        formData={formData}
        setFormData={setFormData}
        setActiveAlert={setActiveAlert}
      />
    );
  } else {
    return <></>;
  }

  // <SurveyStepFive />   => step 5 is exist
};
