import { User } from "@store/plugin";

export const randomstr = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const getUserSubscriptionStatus = (user: User | undefined) => {
  if (!user || !user?.payment_status || !user.payment_status.active) {
    return {
      active: false,
    };
  } else {
    return {
      active: true,
    };
  }
};
