import {
  authParams,
  figmaOauthUrl,
  stripePaymentCancelRedirect,
  stripePaymentSuccessRedirect,
} from "@utils/consts";
import { trackEvent, vscodeButtonClick } from "./analytics";
import { StateData } from "@utils/types";
import { useSelector } from "react-redux";
import { selectDocumentId, selectNodeId, User } from "@store/plugin";
import { generateStripeSessionLink } from "./api";
import { PlanDetails } from "@components/PricingPlans";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const redirectToFigmaAuth = (state: StateData) => {
  window.open(
    `${figmaOauthUrl}?client_id=${authParams.client_id}&redirect_uri=${
      authParams.redirect_uri
    }&scope=${authParams.scope}&state=${JSON.stringify(state)}&response_type=${
      authParams.response_type
    }&type=${authParams.type}`,
    "_blank"
  );
};

export const redirectToVsCodeTrigger = (
  codeSessionId: string,
  buildSessionId: string,
  authToken: string,
  documentId: string,
  nodeId: string
) => {
  trackEvent(vscodeButtonClick, { document_id: documentId, node_id: nodeId });
  window.open(
    authParams.redirect_uri?.replace(
      "login",
      `vscode/${codeSessionId}/${buildSessionId}/${authToken}`
    ),
    "_blank"
  );
};

export const redirectCodeGenToBrowser = (
  codeSessionId: string,
  authToken: string
) => {
  window.open(
    authParams.redirect_uri?.replace(
      "login",
      `codegenBrowser/${authToken}/${codeSessionId}`
    ),
    "_blank"
  );
};

export const getPaymentPageRedirect = (
  user: User | undefined,
  plan: PlanDetails,
  viewMode: "plugin" | "browser"
) => {
  const authToken = localStorage.getItem("authToken");
  let finalSuccessRedirect = stripePaymentSuccessRedirect.replace(
    "{{triggerSource}}",
    viewMode
  );
  finalSuccessRedirect = finalSuccessRedirect.replace(
    "{{planId}}",
    plan.stripePlanId
  );
  finalSuccessRedirect = finalSuccessRedirect.replace(
    "{{planPrice}}",
    "" + plan.price
  );
  let finalCancelRedirect = stripePaymentCancelRedirect.replace(
    "{{triggerSource}}",
    viewMode
  );
  finalCancelRedirect = finalCancelRedirect.replace(
    "{{planId}}",
    plan.stripePlanId
  );
  finalCancelRedirect = finalCancelRedirect.replace(
    "{{planPrice}}",
    "" + plan.price
  );
  return new Promise<string>((resolve, reject) => {
    generateStripeSessionLink(
      {
        signupInfo: {
          name: user?.handle || "",
          email: user?.email || "",
          companyName: "",
        },
        state: "",
        signupFrom: "plugin",
        planId: plan.stripePlanId,
        successUrl: finalSuccessRedirect,
        cancelUrl: finalCancelRedirect,
      },
      authToken!
    )
      .then((resp) => {
        const url = resp.data.redirectURL;
        resolve(url);
      })
      .catch((_) => {
        console.error("Error Generating StripeCheckout URL");
        reject(_);
      });
  });
};
