import Footer from "./Footer";
import React from "react";
import PlanCard from "./PlanCard";
import PlanFeatures from "./PlanFeatures";
import { pricingPlans, STRIPE_WEBFLOW_PLAN_ID } from "@utils/consts";

export interface PlanDetails {
  id: number;
  title: string;
  subTitle: string;
  time: string;
  isDiscountedPrice: boolean;
  originalPrice: number;
  discount: number;
  price: number;
  offerName: string;
  featuresOffered: string[];
  validityText: string;
  stripePlanId: string;
}

interface Props {
  showTestimonials?: boolean;
  viewMode?: "browser" | "plugin";
}



const PricingPlans: React.FC<Props> = ({
  viewMode = "browser",
  showTestimonials = true,
}) => {
  return (
    <div>
      {pricingPlans.map((item) => {
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2">
              {viewMode === "browser" ? (
                <>
                  <PlanFeatures plan={item} />
                  <PlanCard plan={item} viewMode={viewMode} />
                </>
              ) : (
                <>
                  <PlanCard plan={item} viewMode={viewMode} />
                  <PlanFeatures plan={item} />
                </>
              )}
            </div>
            <Footer showTestimonials={showTestimonials} />
          </>
        );
      })}
    </div>
  );
};

export default PricingPlans;
