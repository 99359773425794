import Preview from "@assets/preview.png";
// import Vscode from "@assets/vscode.png";
import helpIcon from "@assets/talk-to-us.png";
import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import CodeMirror, { Extension } from "@uiw/react-codemirror";
// import { createTheme } from "@uiw/codemirror-themes";
import copiedCheck from "@assets/copiedCheck.svg";
import copyBlue from "@assets/copyBlue.svg";
import { SettingsWidget } from "@components/SettingsWidget/Widget";
import {
  selectAuthToken,
  selectBuildSessionID,
  selectCodeSessionID,
  selectDocumentId,
  selectNodeId,
  selectShowFeedback,
  selectUser,
  setPremiumFeatureTriggerText,
} from "@store/plugin";
import { codeResultCopy, codeResultSeen, trackEvent } from "@utils/analytics";
// import { redirectToVsCodeTrigger } from "@utils/redirects";
import { buildURL, chatWidgetLink } from "@utils/consts";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { VscCode } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { FolderItem } from "../helpers";
import "./CodePreview.css";
import { RenderCode } from "./RenderCode";
import gem from "@assets/gem.svg";
import download from "@assets/download.svg";
import { getUserSubscriptionStatus } from "@helper/functions";
import { store } from "@store/index";

interface CodePreview {
  file: FolderItem;
  assetsLoading: boolean;
}
export const CodePreview = ({ file, assetsLoading }: CodePreview) => {
  const nodeId = useSelector(selectNodeId) || "";
  const documentId = useSelector(selectDocumentId) || "";
  const [selectedTab, setSelectedTab] = useState("Code");
  useEffect(() => {
    if (file?.name && !assetsLoading) {
      trackEvent(codeResultSeen, {
        fileName: file.name,
        document_id: documentId,
        node_id: nodeId,
      });
      setSelectedTab("Code");
    } else setSelectedTab(localStorage.getItem("selectedTab") || "Code");
  }, [file, assetsLoading]);

  return (
    <div className="grow relative">
      <TopNav
        selectedFileName={file.name}
        selected={selectedTab}
        setSelected={setSelectedTab}
        assetsLoading={assetsLoading}
        file={file}
      />
      <SettingsWidget />
      <TabItem selected={selectedTab} file={file} />
    </div>
  );
};

interface TabItem {
  file: FolderItem;
  selected: string | null;
}

const codemirrorextension = new Map<string, Extension>([
  ["css", css()],
  ["scss", css()],
  ["html", html()],
  ["jsx", javascript({ jsx: true })],
  ["tsx", javascript({ jsx: true, typescript: true })],
]);

function TabItem({ file, selected }: TabItem) {
  const nodeId = useSelector(selectNodeId) || "";
  const documentId = useSelector(selectDocumentId) || "";
  const user = useSelector(selectUser);
  const userSubscriptionStatus = getUserSubscriptionStatus(user);
  if (selected == "Code") {
    return (
      <CodeMirror
        // theme={codeMirrorTheme}
        value={file.value}
        height="100%"
        className="w-full codetab-height bg-[#F7F7F7]"
        width="100%"
        onCopy={() => {
          if (!userSubscriptionStatus.active) {
            setTimeout(async () => {
              await navigator.clipboard.writeText("Oops! Please Subscribe!");
            }, 100);
          }
          trackEvent(codeResultCopy, {
            file: file.name,
            document_id: documentId,
            node_id: nodeId,
          });
        }}
        extensions={[codemirrorextension.get(file.filetype) || html()]}
        onChange={(value, viewUpdate) => {}}
        onBlur={() => {}}
      />
    );
  }

  if (selected == "Preview") {
    return <RenderCode file={file} />;
  }

  return <span>UnImplemented</span>;
}

/**
 * TopNavBar
 * @returns
 */
interface TopNavBarProps {
  selected: string | null;
  setSelected: (selected: string) => void;
  selectedFileName: string;
  assetsLoading: boolean;
  file: FolderItem;
}
const TopNav = ({
  selected,
  setSelected,
  selectedFileName,
  assetsLoading,
  file,
}: TopNavBarProps) => {
  const copyBtn = useRef<any>();
  const copyBtnIconRef = useRef<any>();
  const onCopy = () => {
    const icon = copyBtnIconRef.current as HTMLImageElement;
    const copyBtnElem = copyBtn.current as HTMLButtonElement;

    icon.src = copiedCheck;
    copyBtnElem.classList.replace("text-[#0075FF]", "text-[#02b502]");
    copyBtnElem.classList.replace("border-[#0075FF]", "border-[#02b502]");
    copyBtnElem.disabled = true;

    setTimeout(() => {
      icon.src = copyBlue;
      copyBtnElem.classList.replace("text-[#02b502]", "text-[#0075FF]");
      copyBtnElem.classList.replace("border-[#02b502]", "border-[#0075FF]");
      copyBtnElem.disabled = false;
    }, 2000);
  };
  const user = useSelector(selectUser);
  const userSubscriptionStatus = getUserSubscriptionStatus(user);
  const showFeedback = useSelector(selectShowFeedback);
  const sessionId = useSelector(selectCodeSessionID);
  const buildSessionId = useSelector(selectBuildSessionID);
  const authToken = useSelector(selectAuthToken);
  const nodeId = useSelector(selectNodeId) || "";
  const documentId = useSelector(selectDocumentId) || "";
  return (
    <nav className="bg-[#F7F7F7] h-[74px] flex items-center p-[25px] justify-between">
      <div className="flex text-[16px] items-center font-medium">
        {selectedFileName}{" "}
        {userSubscriptionStatus.active ? (
          <CopyToClipboard text={file.value as string} onCopy={onCopy}>
            <>
              <button
                disabled={selected !== "Code"}
                ref={copyBtn}
                className={`flex items-center justify-center rounded-[5px] p-[2px] text-[10px] ml-5 border-[1px]
          ${selected !== "Code" ? "text-[#4776ab]" : "text-[#0075FF]"}
          ${selected !== "Code" ? "border-[#4776ab]" : "border-[#0075FF]"}
          ${selected !== "Code" ? "cursor-not-allowed" : "cursor-pointer"}
          ${selected !== "Code" ? "bg-[#e1e1e1]" : "bg-transparent"}
          `}
              >
                Copy this code
                <img
                  ref={copyBtnIconRef}
                  src={copyBlue}
                  alt=""
                  className="ml-[2px] w-[12px] h-[12px]"
                />
              </button>
            </>
          </CopyToClipboard>
        ) : (
          <>
            <button
              disabled={selected !== "Code"}
              ref={copyBtn}
              className={`flex items-center justify-center rounded-[5px] p-[2px] text-[10px] ml-5 border-[1px]
            ${selected !== "Code" ? "text-[#4776ab]" : "text-[#0075FF]"}
            ${selected !== "Code" ? "border-[#4776ab]" : "border-[#0075FF]"}
            ${selected !== "Code" ? "cursor-not-allowed" : "cursor-pointer"}
            ${selected !== "Code" ? "bg-[#e1e1e1]" : "bg-transparent"}
            `}
              onClick={() => {
                store.dispatch(setPremiumFeatureTriggerText("Code copy"));
              }}
            >
              Copy this code
              <img
                ref={copyBtnIconRef}
                src={copyBlue}
                alt=""
                className="ml-[2px] w-[12px] h-[12px]"
              />
            </button>

            <img src={gem} alt="" className="ml-1 w-[18px]" />
          </>
        )}
      </div>

      <div className="inline-flex flex-nowrap">
        {selected === "Code" && (
          <>
          <a
            href={userSubscriptionStatus.active ? buildURL + "/export/" + sessionId : undefined}
            className="flex items-center justify-center gap-[2px] w-[45%] cursor-pointer shadow-button-shadow bg-[#6930CA] rounded-xl text-center text-[12px] font-[500] text-white py-[13px] px-[12px] mx-[10px]"
            onClick={async () => {
              if(!userSubscriptionStatus.active){
                store.dispatch(setPremiumFeatureTriggerText("Export"));
              }
            }}
          >
              <img src={download} alt="" className="w-[18px]" />
              {!userSubscriptionStatus.active && <img src={gem} alt="" className="w-[18px]" />}
              
            </a>
            <button
              disabled={assetsLoading || file.filetype === "css"}
              onClick={() => {
                setSelected("Preview");
                localStorage.setItem("selectedTab", "Preview");
              }}
              className="cursor-pointer shadow-button-shadow mx-[10px] px-[25px] justify-center disabled:opacity-50 inline-flex bg-[#FFD803] items-center rounded-xl py-[10px] w-full"
            >
              <img src={Preview} className="h-[12px] mr-[10px]" />
              <span className="text-[14px] text-black">Preview</span>
            </button>
            <a
              href={chatWidgetLink}
              target="_blank"
              referrerPolicy="no-referrer"
              // onClick={() =>
              //   redirectToVsCodeTrigger(
              //     sessionId || "",
              //     buildSessionId || "",
              //     authToken || "",
              //     documentId || "",
              //     nodeId || ""
              //   )
              // }
              className="cursor-pointer shadow-button-shadow mx-[10px] px-[25px] border bg-white justify-center inline-flex items-center rounded-xl py-[10px] w-full"
            >
              <img src={helpIcon} className="h-[20px] mr-[10px]" />
              <div className="text-[14px] whitespace-nowrap leading-[10px]">
                Talk to us
              </div>
            </a>
          </>
        )}
        {selected === "Preview" && (
          <button
            onClick={() => {
              setSelected("Code");
              localStorage.setItem("selectedTab", "Code");
            }}
            className="cursor-pointer shadow-button-shadow mx-[10px] px-[25px] justify-center disabled:opacity-50 inline-flex bg-[#FFD803] items-center rounded-xl py-[10px] w-full"
          >
            <VscCode className="h-[20px] mr-[10px]" />
            <span className="text-[14px] text-black">View Code</span>
          </button>
        )}
      </div>
    </nav>
  );
};
